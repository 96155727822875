body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100vh;
    text-align: center;
}

.header {
    background-color: #0056b3;
    color: #fff;
    padding: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-wrapper {
    display: flex;
    flex: 1;
    padding: 20px;
    gap: 10px;
}

.sidebar {
    flex: 1;
    background-color: #e9ecef;
    padding: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.main-content {
    flex: 3;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: left;
}

.ads-space {
    background: #f8f9fa;
    border: 1px dashed #bbb;
    height: 150px;
    text-align: center;
    line-height: 150px;
    color: #888;
    font-size: 14px;
    border-radius: 5px;
}
