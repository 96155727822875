/* Estilo Global */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

/* Contêiner principal */
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

/* Estilo da página */
.home {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Cartões */
.cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0;
  gap: 20px;
}

/* Espaço para anúncios */
.ads-space {
  width: 100%;
  margin: 20px auto;
  background-color: #e9ecef;
  border: 1px dashed #ccc;
  text-align: center;
  line-height: 100px;
  height: 100px;
  font-size: 0.9rem;
  color: #6c757d;
  border-radius: 8px;
}

/* Seções informativas */
.info-section {
  transform: translateY(100px); /* Fora da tela inicialmente */
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
  background-color: #f0f0f0;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.home-container .content .info-section {
  transform: translateY(0); /* Fica na posição original ao rolar */
  opacity: 1;  /* Tornar visível */
}

/* Links e botões */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Responsividade */
@media (max-width: 768px) {
  .home {
      flex-direction: column;
      gap: 10px;
  }

  .cards {
      justify-content: center;
      gap: 10px;
  }

  .ads-space {
      height: 80px;
      line-height: 80px;
      font-size: 0.8rem;
  }

  .info-section {
      padding: 15px;
      margin: 15px 0;
  }
}

/* Estilo para a seção de links */
.links {
  text-align: center;
  margin-top: 40px; /* Espaço superior para separar do conteúdo principal */
  margin-bottom: 20px; /* Espaço inferior para garantir que os links não fiquem colados */
  font-size: 14px;
}

/* Estilo para os links */
.links a {
  color: #007bff;
  text-decoration: none;
  margin: 0 15px; /* Adicionando um pouco mais de espaçamento horizontal */
  font-weight: bold;
  transition: color 0.3s ease; /* Adicionando suavidade na mudança de cor */
}

/* Estilo para os links ao passar o mouse */
.links a:hover {
  text-decoration: underline;
  color: #0056b3; /* Mudando a cor do link ao passar o mouse */
}

/* Estilo adicional para a seção de conteúdo */
.home-container {
  padding-bottom: 20px; /* Garantir que a parte inferior tenha um bom espaçamento */
}

/* Estilo para a sugestão de blog */
.blog-suggestion {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-align: left; /* Alinha o texto à esquerda */
}

/* Justifica o texto dentro da sugestão do blog */
.blog-suggestion p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  text-align: justify; /* Justifica o texto */
}

.blog-suggestion h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.blog-suggestion .blog-link {
  color: #0077cc;
  text-decoration: none;
  font-weight: bold;
}

.blog-suggestion .blog-link:hover {
  text-decoration: underline;
}

/* Ajuste para os ícones da seção blog-suggestion */
.blog-suggestion p span {
  font-size: 1.5em; /* Tamanho maior para os ícones */
  margin-right: 8px;
}
