.header {
    background-color: #007bff;
    color: white;
    padding: 20px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .ads-space {
    margin: 20px 0;
    background-color: #e9ecef;
    border: 1px dashed #ccc;
    color: #6c757d;
    text-align: center;
    line-height: 50px;
    height: 50px;
  }  