.feed-carousel {
    margin: 20px 0;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 8px;
    text-align: left;
  }
  
  .carousel {
    display: flex;
    gap: 20px;
    overflow-x: auto;
  }
  
  .news-item {
    flex: 0 0 300px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
  }
  
  .news-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .news-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .news-description {
    font-size: 0.9rem;
    color: #555;
  }