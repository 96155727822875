.privacy-policy-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .privacy-policy-container h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  .privacy-policy-container p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container .privacy-policy-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .back-to-home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .back-to-home-link:hover {
    background-color: #45a049;
  }