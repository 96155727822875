.financial-market-container {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333; /* Texto mais suave */
    padding: 20px;
    background-color: #f9f9f9; /* Fundo claro */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
  }
  
  .financial-market-container h1 {
    color: #f0f3f7; /* Azul do tema */
    margin-bottom: 10px;
  }

  .financial-market-container h2 {
    color: #004ba0; /* Azul do tema */
    margin-bottom: 10px;
  }
  
  .financial-market-container p {
    margin-bottom: 15px;
  }
  
  .financial-market-container ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .financial-market-container ul li {
    margin-bottom: 10px;
  }
  
  .header {
    background-color: #004ba0; /* Azul mais escuro */
    color: white; /* Texto branco */
    padding: 20px;
    text-align: center; /* Centraliza o texto */
    font-size: 1.8rem; /* Ajusta o tamanho */
  }
  