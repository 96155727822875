/* Estilo principal para o card */
.cotacao-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px; /* Altura fixa ou min-height para garantir uniformidade */
}

/* Efeito ao passar o mouse sobre o card */
.cotacao-card:hover {
  transform: scale(1.05);
}

/* Título do card */
.cotacao-card h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

/* Nome da moeda */
.cotacao-card .nome {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 8px;
}

/* Estilo geral dos parágrafos no card */
.cotacao-card p {
  margin: 4px 0;
  font-size: 1rem;
  flex-grow: 1;
}

/* Destaque do valor do Real */
.valor-real-destaque {
  font-size: 1.1rem; /* Aumenta o tamanho do valor */
  font-weight: bold; /* Deixa o valor em negrito */
  color: #031307; /* Verde, que é uma cor associada ao sucesso e estabilidade */
  border-radius: 5px; /* Cantos arredondados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transição suave */
}

/* Efeito de hover no valor destacado */
.valor-real-destaque:hover {
  transform: scale(1.1); /* Aumenta um pouco o valor ao passar o mouse */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Sombra mais forte */
  cursor: pointer; /* Muda o cursor para indicar interatividade */
}

/* Estilos para variação positiva e negativa */
.positivo {
  color: green;
  font-weight: bold;
}

.negativo {
  color: red;
  font-weight: bold;
}

/* Alinhamento dos cards */
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsividade com grade flexível */
  gap: 16px; /* Espaçamento entre os cards */
  justify-content: center; /* Alinha os cards ao centro */
  margin: 0 auto; /* Alinha o conteúdo centralizado */
}

/* Garantir que os cards ocupem o mesmo espaço */
.cotacao-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%; /* Garante que o card ocupe a largura máxima disponível */
}

/* Media Query para telas menores (como mobile) */
@media (max-width: 768px) {
  .cards {
    grid-template-columns: 1fr; /* Um card por linha em telas pequenas */
  }
}
