.back-to-home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .back-to-home-link:hover {
    background-color: #0056b3;
  }
  